import { Grid, Typography } from "@material-ui/core"
import React, { ReactElement } from "react"
import InstagramEmbed from "react-instagram-embed"

import BlogPost from "../../../../components/BlogPost"
import { SearchResults } from "../../../../components/SearchResults"

interface Props {
  data: any
  location: Location
  pageContext: any
}

export default function Post(props: Props): ReactElement {
  const { location, pageContext } = props
  return (
    <BlogPost
      location={location}
      pageContext={pageContext}
      title="Mäntel mit Streifen"
      category="Wintertrends 2019/2020"
    >
      <Grid container={true} spacing={1}>
        <Grid item={true} xs={12} md={6}>
          <Typography paragraph={true}>
            Elsa Hosk macht es uns vor und wir machen es nach. Ein mit Karo-Muster versehener
            oversized Mantel von Thom Browne. Egal ob Karo-Muster, Hahnentritt-Muster oder normale
            Streifen: Streifen sind überall auf Mänteln in. Designer aller Welt beeindrucken uns mit
            ihren gestreiften Mänteln. Ein schöner Mantel kann das Outfit vervollständigen und einen
            glamourösen Look verleihen.
          </Typography>
          <InstagramEmbed
            url="https://www.instagram.com/p/B3CfGuGpa8w/"
            hideCaption={true}
            injectScript={true}
          />
        </Grid>
        <Grid item={true} xs={12} md={6}>
          <SearchResults
            breakpoints={{
              lg: 6,
              md: 6,
              xs: 6,
            }}
            results={[
              {
                href: "https://www.awin1.com/pclick.php?p=24609278311&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32273979?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "TOM TAILOR Denim Kurzmantel",
                price: 99.99,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24609216139&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/32714282?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "b.young Kurzmantel",
                price: 129.95,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24966186827&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/35156380?w=1060&h=1500",
                    title: "",
                  },
                ],
                name: "s.Oliver BLACK LABEL Wollmantel",
                price: 229,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24704541581&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/34850419?w=1750&h=2500",
                    title: "",
                  },
                ],
                name: "TOMMY JEANS Kurzmantel",
                price: 189.9,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24953405243&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src:
                      "https://i.otto.de/i/otto/c1da099d-839e-5efa-9a66-00258787b878?w=1500&h=1900",
                    title: "",
                  },
                ],
                name: "Samoon Mantel",
                price: 229,
              },

              {
                href: "https://www.awin1.com/pclick.php?p=24608444975&a=667803&m=14336",
                images: [
                  {
                    alt: "",
                    src: "https://i.otto.de/i/otto/29639112?w=900&h=1200",
                    title: "",
                  },
                ],
                name: "SELECTED FEMME Wollmix Mantel",
                price: 219.99,
              },
            ]}
          />
        </Grid>
      </Grid>
    </BlogPost>
  )
}
